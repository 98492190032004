<template>
  <div class="main">
    <div class="wrapper">
      <Header :outletName="'test'" />
      <v-container grid-list-xs>
        <v-row v-if="user.length < 1 && step == 1">
          <v-col cols="12">
            <div
              class="mb-2"
              style="
                color: grey;
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
              "
            >
              User Details
            </div>
            <v-divider></v-divider>
            <div style="margin-top: 10px">
              <v-btn
                block
                large
                color="white"
                dark
                @click="step++"
                outlined="false"
              >
                <div class="flex">
                  <div
                    class=""
                    style="
                      margin-left: -10px;
                      color: #f85757;
                      text-transform: none;
                      font-weight: bold;
                    "
                  >
                    Add New User
                  </div>
                </div>
              </v-btn>
              <div
                style="
                  font-style: italic;
                  font-size: 13px;
                  margin-left: 8px;
                  margin-top: -5px;
                  margin-bottom: 20px;
                  color: #8e8e8e;
                "
              >
                *please add new user to proceed order
              </div>
            </div>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="user.length > 1 || step == 2">
          <v-col>
            <div
              class="mb-2"
              style="
                color: grey;
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
              "
            >
              User Details
            </div>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-radio-group>
                  <div
                    v-if="this.unselectedUser.length > 0"
                    style="margin-top: -30px"
                  >
                    <div
                      color="white"
                      style="margin-top: 10px"
                      class="rounded-t-xl"
                      v-for="(v, i) in this.unselectedUser"
                      :key="i"
                    >
                      <div>
                        <v-col cols="12">
                          <div class="flex justify-space-between">
                            <div @click="toggleUserSelection(i)">
                              <div
                                :class="
                                  isSelected(v)
                                    ? 'list__users__as__primary__checked__container'
                                    : 'list__users__as__primary__checked__container_uncheck'
                                "
                              >
                                <div
                                  :class="
                                    isSelected(v)
                                      ? 'list__users__as__primary__checked'
                                      : 'list__users__as__primary__unchecked'
                                  "
                                ></div>
                              </div>
                            </div>
                            <div
                              class="flex flex-column source ml-3"
                              style="line-height: 1.5; margin-bottom: -90px"
                            >
                              <h4 @click="toggleUserSelection(i)">
                                {{ v.name }}
                              </h4>
                              <div class="flex justify-space-between">
                                <div @click="toggleUserSelection(i)">
                                  {{ v.phone }}
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  "
                                >
                                  <v-btn
                                    @click="editUser(v)"
                                    style="background-color: white"
                                    small
                                    block
                                    elevation="0"
                                    ><v-img
                                      :src="
                                        require('@/assets/img/EditProperty.png')
                                      "
                                    ></v-img
                                  ></v-btn>
                                </div>
                              </div>
                              <div @click="toggleUserSelection(i)">
                                <div class="user-details lato">
                                  {{ v.email }}
                                </div>
                                <div class="add-details lato">
                                  {{ v.address }}
                                </div>
                                <div class="user-details lato">
                                  {{ v.address2 }}
                                </div>
                                <div class="user-details lato">
                                  {{ v.postcode }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </div>
                      <v-divider class="mt-10"></v-divider>
                    </div>
                  </div>
                  <div v-if="this.unselectedUser.length > 0">
                    <!-- <v-divider></v-divider> -->
                    <v-btn
                      block
                      large
                      color="white"
                      dark
                      @click="step++"
                      outlined="false"
                    >
                      <div class="flex">
                        <div
                          class=""
                          style="
                            color: #f85757;
                            text-transform: none;
                            font-weight: bold;
                          "
                        >
                          Add New User
                        </div>
                      </div>
                    </v-btn>
                  </div>
                </v-radio-group>
              </v-window-item>
              <v-window-item :value="2">
                <v-card color="white" class="rounded-t-xl" min-height="90vh">
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="flex flex-column blue-grey--text mt-3"
                        style="line-height: 1"
                      >
                        <v-form ref="form" lazy-validation>
                          <v-text-field
                            name="name"
                            outlined
                            color="black"
                            clearable
                            :rules="[(v) => !!v || 'Name is required']"
                            label="Name"
                            dense
                            v-model="newUser.name"
                          ></v-text-field>
                          <v-text-field
                            name="name"
                            outlined
                            color="black"
                            type="number"
                            clearable
                            dense
                            :rules="[(v) => !!v || 'Phone Number is required']"
                            label="Phone Number"
                            v-model="newUser.phone"
                          ></v-text-field>
                          <div v-if="isPaymentOnline || isShoplink">
                            <v-text-field
                              name="name"
                              outlined
                              color="black"
                              type="email"
                              dense
                              clearable
                              :rules="[
                                (v) =>
                                  !v ||
                                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                    v
                                  ) ||
                                  'E-mail must be valid',
                                (v) => !!v || 'Email is required',
                              ]"
                              label="Email"
                              v-model="newUser.email"
                            ></v-text-field>
                          </div>
                          <div v-if="isShoplink">
                            <v-text-field
                              name="name"
                              outlined
                              color="black"
                              clearable
                              :rules="[(v) => !!v || 'Address is required']"
                              label="Address"
                              dense
                              v-model="newUser.address"
                            ></v-text-field>
                            <v-text-field
                              name="name"
                              outlined
                              color="black"
                              clearable
                              dense
                              :rules="[(v) => !!v || 'Address 2 is required']"
                              label="Address 2"
                              v-model="newUser.address2"
                            ></v-text-field>
                            <v-text-field
                              name="name"
                              outlined
                              color="black"
                              clearable
                              dense
                              :rules="[
                                (v) => !!v || 'Postcode is required',
                                (v) =>
                                  (v && v.length <= 5) ||
                                  'Postcode must be less than 5 characters',
                              ]"
                              label="Postcode"
                              counter="5"
                              v-model="newUser.postcode"
                            ></v-text-field>
                          </div>
                        </v-form>
                      </div>
                      <div
                        class="flex flex-column blue-grey--text ml-3 mt-3"
                        v-if="newUser.address && newUser.address2"
                      >
                        <v-btn
                          elevation="2"
                          @click="getUpdateGeo()"
                          v-if="showMaps == false"
                          >Check Pin Point</v-btn
                        >
                        <v-card class="justify-center" elevation="2" v-else>
                          <div class="text-center" v-if="loading != 1">
                            <v-progress-circular
                              indeterminate
                              color="green"
                            ></v-progress-circular>
                          </div>
                          <div v-else>
                            <gmap-map
                              :zoom="14"
                              :center="center"
                              :options="{ disableDefaultUI: true }"
                              style="width: 100%; height: 300px"
                            >
                              <gmap-marker
                                :position="center"
                                :draggable="true"
                                @dragend="updateCoordinates"
                              ></gmap-marker>
                            </gmap-map>
                          </div>
                        </v-card>
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <div class="user_list_container__footer">
                    <div class="user_list_container__footer__add_cta">
                      <v-btn
                        color="grey"
                        width="45%"
                        dark
                        large
                        elevation="5"
                        rounded-md
                        @click="handleBack"
                        >Back</v-btn
                      >
                      <v-btn
                        color="#F85757"
                        width="45%"
                        dark
                        large
                        elevation="5"
                        rounded-md
                        @click="save('save')"
                        >save</v-btn
                      >
                    </div>
                  </div>
                </v-card>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="step == 1" class="user_list_container__footer">
        <v-btn
          block
          color="grey"
          dark
          large
          elevation="5"
          rounded-md
          @click="navigateToProduct"
          style="position: sticky; bottom: 0"
        >
          <span style="text-transform: capitalize !important">
            {{
              cart.length > 0 && this.user.length > 0
                ? "Back to Order"
                : "Back to Menu"
            }}</span
          >
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "../../common/Header.vue";

export default {
  name: "Users",
  components: {
    Header,
  },
  data() {
    return {
      step: 1,
      status: null,
      isShoplink: false,
      isSessionQR: false,
      isStaticQR: false,
      selectedUserId: "selectedUser",
      isPaymentOnline: false,
      isPayAtCounter: false,
      newUser: {
        name: "",
        address: "",
        address2: "",
        postcode: "",
        email: "",
        phone: "",
      },
      rules: [(v) => !!v || "Name is required"],
      showSave: false,
      showMaps: false,
      center: {},
      locationMarkers: null,
      locPlaces: [],
      existingPlace: null,
      loading: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUsers",
      cart: "getCart",
      franchise: "getFranchise",
    }),
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
    unselectedUser() {
      return this.user;
    },
  },
  methods: {
    handleBack() {
      this.step = 1;
      this.newUser = {
        name: "",
        address: "",
        address2: "",
        postcode: "",
        email: "",
        phone: "",
      };
    },
    checkPaymentMethod() {
      this.isPayAtCounter =
        this.franchise.v2_qr_payment_method.includes("pay_at_counter");
      this.isPaymentOnline =
        this.franchise.v2_qr_payment_method.includes("online_payment");
    },
    toggleUserSelection(userIndex) {
      this.$store.dispatch("setUser", userIndex);
    },
    navigateToProduct() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      const isStaticQR = outlet_id && table_id;
      if (this.cart.length > 0 && this.user.length > 0) {
        if (isStaticQR) {
          this.$router.push({
            name: "Cart",
            params: { child: this.$route.params.child, outlet_id, table_id },
          });
        } else if (session) {
          this.$router.push({
            name: "SessionCart",
            params: { session },
          });
        } else {
          this.$router.push({
            name: "ShoplinkCart",
            params: { child: this.$route.params.child },
          });
        }
      } else {
        if (isStaticQR) {
          this.$router.push({
            name: "Products",
            params: { child: this.$route.params.child, outlet_id, table_id },
          });
        } else if (session) {
          this.$router.push({
            name: "SessionProducts",
            params: { session },
          });
        } else {
          this.$router.push({
            name: "ShoplinkProducts",
            params: { child: this.$route.params.child },
          });
        }
      }
      localStorage.removeItem("selectedProduct");
    },
    updateCoordinates(location) {
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },

    toCart() {
      if (this.user.length < 1) {
        this.$router.push({
          name: "Page",
          params: { child: this.$route.params.child, page: "products" },
        });
      } else
        this.$router.push({
          name: "Page",
          params: { child: this.$route.params.child, page: "cart" },
        });
    },

    select() {
      this.showSave = true;
    },

    isSelected(i) {
      return i.selected == true;
    },

    set(i) {
      let a = this.user.findIndex((d) => d.name == i.name);
      this.$store.dispatch("setUser", a);
      this.step = 1;
    },

    editUser(value) {
      let a = this.user.findIndex((d) => d.name == value.name);
      this.status = a;
      this.newUser = { ...value };
      this.step++;
    },

    async getUpdateGeo() {
      this.showMaps = true;
      let data = null;
      let a = this.user.findIndex((d) => d.name == this.newUser.name);
      let b = this.user[a];

      data = {
        address: this.newUser.address,
        address2: this.newUser.address2,
      };

      if (a >= 0) {
        if (
          b.address != this.newUser.address ||
          b.address2 != this.newUser.address2 ||
          !b.lat
        ) {
          let fullAddr = await this.$store.dispatch("fetchGeocode", data);
          this.loading = 1;
          this.center = {
            lat: fullAddr.latitude,
            lng: fullAddr.longitude,
          };
        } else {
          this.loading = 1;
          this.center = {
            lat: b.lat,
            lng: b.lng,
          };
        }
      } else {
        let fullAddr = await this.$store.dispatch("fetchGeocode", data);
        this.loading = 1;
        this.center = {
          lat: fullAddr.latitude,
          lng: fullAddr.longitude,
        };
      }
    },

    save() {
      let a = this.$refs.form.validate();
      if (this.center == null) {
        this.getUpdateGeo();
      }
      if (a) {
        this.$store
          .dispatch("saveUser", {
            user: { ...this.newUser, ...this.center },
            status: this.status,
          })
          .then(() => {
            this.status = null;
            this.newUser = {
              name: "",
              address: "",
              address2: "",
              postcode: "",
              email: "",
              phone: "",
            };
            this.step = 1;
            // this.$router.push({
            //   name: "Page",
            //   params: { child: this.$route.params.child, page: "cart" },
            // });
          });
      }
    },
  },
  async beforeMount() {
    this.checkPaymentMethod();
    const { table_id, outlet_id } = this.$route.params;

    if (!table_id && !outlet_id) {
      this.isShoplink = true;
    }
    if (this.$route.params.session) {
      this.isSessionQR = true;
      this.isShoplink = false;
    }
    if (table_id && outlet_id) {
      this.isStaticQR = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.list__users__as__primary__checked__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #f85757;

  .list__users__as__primary__checked {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f85757;
  }
}

.list__users__as__primary__checked__container_uncheck {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: grey;

  .list__users__as__primary__unchecked {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user_list_container__footer {
  width: 100%;
  position: sticky !important;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0px;

  .user_list_container__footer__add_cta {
    padding-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.add-details {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 290px !important;
}

.user-details {
  overflow: hidden;
  white-space: nowrap;
  max-width: 0px;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
}

.custom-radio input:checked + .v-input--selection-controls .v-icon {
  color: red;
  /* Ganti dengan warna yang Anda inginkan */
}

input[type="radio"]:checked + label::before {
  background-color: red;
}
</style>
